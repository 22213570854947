[class*='-first-display'] {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 21px;
    height: 22px;
    bottom: 30px;
    left: 50%;
    transform: translateX(-50%);
    background: url("/sites/all/themes/wx_theme/assets/images/sprite.png") -161px 0 no-repeat;
  }

  .wrapper {
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: cover;
  }

  .inner {
    min-height: 450px;
    background: rgba(4,30,61,0.7) url("/sites/all/themes/wx_theme/assets/images/shadow.png") 50% 50% no-repeat;
    text-shadow: 0 11px 18px rgba(0, 0, 0, 0.13);
  }

  .container {
    height: 100vh;
    min-height: 450px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    padding: 100px 15px 160px;
    user-select: none;
    cursor: default;
  }

  .title {
    margin: 0 0 20px;
    color: #fff;
    font-size: 65px;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
  }

  .conditions {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 120px;
    color: #fff;
    font-size: 55px;
    font-weight: 300;
  }

  .value {
    font-weight: 700;
  }

  .punkt {
    position: relative;
    margin-left: 60px;

    &:before {
      content: '';
      position: absolute;
      width: 36px;
      height: 33px;
      top: 22px;
      left: -60px;
      background: url("/sites/all/themes/wx_theme/assets/images/sprite.png") -52px 0 no-repeat;
    }
  }

  .ah-button {
    position: relative;
    display: block;
    width: 380px;
    height: 72px;
    min-height: 72px;
    background: #ff6800;
    padding: 10px 105px 10px 25px;
    color: #fff;
    font-size: 35px;
    font-weight: 300;
    border-radius: 0;
    border: none;
    border-bottom: 3px solid #081902;
    outline: none;

    &:before {
      content: '';
      position: absolute;
      width: 63px;
      height: 25px;
      top: 24px;
      right: 20px;
      background: url("/sites/all/themes/wx_theme/assets/images/sprite.png") -93px 0 no-repeat;
    }

    &:hover,
    &:focus {
      color: #fff;
      outline: none;
    }
  }
}

.content-services {
  padding: 75px 0 110px;
  background: linear-gradient(to bottom, #fcfcfc 0, #fff 50px);
}

.service {
  .image {
    max-width: 360px;
    float: left;
  }

  .rs {
    margin-left: 390px;
  }

  .price-list {
    overflow: hidden;
  }

  .cost {
    margin-bottom: 20px;
    font-size: 18px;
    font-weight: 600;

    .area {
      margin-bottom: 5px;
      font-size: 29px;
      font-weight: 700;
    }

    .container-inline {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      position: relative;
      max-width: 375px;
      color: #333;
      font-size: 24px;
      font-weight: 300;

      &:after {
        content:'. . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . . ';
        display: block;
        position: absolute;
        top: 15px;
        left: 0;
        white-space: nowrap;
        overflow: hidden;
        font-size: 11px;
        line-height: 11px;
      }
    }

    .container-inline + .container-inline {
      margin-top: 6px;
    }

    .double-field-first {
      padding-right: 5px;
      background: #fff;
      z-index: 1;
    }

    .double-field-second {
      padding-left: 5px;
      background: #fff;
      z-index: 1;
    }
  }
}

.view-fp-evacuator-cost {
  .views-row:not(:first-child) {
    position: relative;
    margin-top: 50px;
    padding-top: 50px;

    &:before {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translateX(-50%);
      width: calc(100% + 200px);
      max-width: 95vw;
      height: 1px;
      background: #eee;
    }
  }

  .title {
    font-size: 40px;
    font-weight: 300;
    line-height: 44px;
  }
}

.content-gallery {
  padding-bottom: 90px;
  overflow: hidden;

  .view-content {
    position: relative;
  }

  .gallery-title {
    margin-right: 25px;
  }

  .title-wr {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  a.open-text.collapsed {
    transform: none;
  }

  .collapsing {
    transition-timing-function: linear;
    transition-duration: .3s;
  }

  a.open-text {
    display: block;
    position: relative;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    border: none;
    background: #f2f2f2;
    transform: rotate(180deg);
    transition: transform .5s ease-in-out;

    &:before {
      content: '';
      position: absolute;
      width: 22px;
      height: 13px;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      background: url("/sites/all/themes/wx_theme/assets/images/sprite.png") -187px 0 no-repeat;
    }
  }

  .text {
    width: 100%;
    color: #333;
    font-size: 24px;
    font-weight: 300;
  }

  .gallery-wr {
    width: calc(100% + 30px);
    margin-left: -15px;
  }

  .gallery {
    img {
      margin: 0 15px;

      &:focus {
        outline: none;
      }
    }
  }

  .slick-button-prev,
  .slick-button-next {
    position: absolute;
    width: 18px;
    height: 104px;
    top: 50%;
    transform: translate(0, -50%);
    z-index: 1;
  }

  .slick-button-prev {
    left: -20px;
    background: url("/sites/all/themes/wx_theme/assets/images/sprite.png") 0 -38px no-repeat;
  }

  .slick-button-next {
    right: -20px;
    background: url("/sites/all/themes/wx_theme/assets/images/sprite.png") -23px -38px no-repeat;
  }
}


/*-----------------------
          Modal
------------------------*/

.modal-open {
  overflow: auto;
}

.modal {
  top: 50%;
  transform: translateY(-50%);

  .close {
    position: absolute;
    top: 0;
    right: 0;
    width: 46px;
    height: 46px;
    background: url("/sites/all/themes/wx_theme/assets/images/modal-close.png") 0 0 no-repeat;
    outline: none;

    &:focus {
      outline: none;
    }
  }

  .modal-dialog {
    max-width: 380px;
    margin: 0 auto;
  }

  .modal-content {
    position: relative;
    max-width: 380px;
    height: 400px;
    min-height: 400px;
    padding: 90px 20px 0;
    border-radius: 0;
    border: 1px solid #ff6800;

    textarea {
      border-bottom: 0;

      &::-webkit-input-placeholder {
        padding-top: 10px;
      }

      &::-moz-placeholder {
        padding-top: 10px;
      }

      &:-moz-placeholder {
        padding-top: 10px;
      }

      &:-ms-input-placeholder {
        padding-top: 10px;
      }
    }

    .webform-submit {
      margin: 0 auto;
    }
  }
}


/*--------Media-------*/

@media all and (max-width: 1199px) {
  [class*='-first-display'] {
    .title {
      font-size: 55px;
    }

    .conditions {
      font-size: 45px;
    }

    .punkt:before {
      top: 17px;
    }
  }
}

@media all and (max-width: 991px) {
  [class*='-first-display'] {
    .title {
      font-size: 45px;
    }

    .conditions {
      display: inline-block;
      width: auto;
      font-size: 40px;
    }

    .punkt:before {
      top: 12px;
    }
  }
}

@media all and (max-width: 767px) {
  [class*='-first-display'] {
    &:before {
      bottom: 15px;
    }

    .container {
      padding: 100px 15px 40px;
      justify-content: center;
    }

    .title {
      font-size: 33px;
    }

    .conditions {
      margin-bottom: 50px;
      font-size: 30px;
    }

    .punkt:before {
      top: 2px;
    }

    .punkt + .punkt {
      margin-top: 10px;
    }

    .ah-button {
      max-width: 300px;
      height: 60px;
      min-height: 60px;
      font-size: 24px;

      &:before {
        top: 17px;
      }
    }
  }

  .content-services {
    padding: 55px 15px 70px;
  }

  .service {
    .image {
      float: none;
    }

    .rs {
      margin: 25px 0 0;
    }
  }

  .content-gallery {
    padding: 0 15px 60px;

    .slick-button-prev {
      left: 10px;
      background: rgba(255,255,255,0.5) url("/sites/all/themes/wx_theme/assets/images/sprite.png") 0 -38px no-repeat;
    }

    .slick-button-next {
      right: 10px;
      background: rgba(255,255,255,0.5) url("/sites/all/themes/wx_theme/assets/images/sprite.png") -23px -38px no-repeat;
    }
  }

  #myModal {
    min-height: 400px;
  }

  .modal {
    .modal-dialog {
      max-width: 300px;
    }

    .modal-content {
      max-width: 300px;
      height: 400px;
      padding: 90px 20px 0;
    }
  }
}
