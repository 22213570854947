// Page Layout
// -----------------------------------------------------------------------------

@font-face {
  font-family: 'PFHandbookPro';
  src: url('../fonts/pfhandbookpro-regular.eot');
  src: url('../fonts/pfhandbookpro-regular.eot?#iefix') format('embedded-opentype'), url('../fonts/pfhandbookpro-regular.woff2') format('woff2'), url('../fonts/pfhandbookpro-regular.woff') format('woff'), url('../fonts/pfhandbookpro-regular.ttf') format('truetype'), url('../fonts/pfhandbookpro-regular.svg#pfhandbookproregular') format('svg');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'PFHandbookPro';
  src: url('../fonts/pfhandbookpro-thin.eot');
  src: url('../fonts/pfhandbookpro-thin.eot?#iefix') format('embedded-opentype'), url('../fonts/pfhandbookpro-thin.woff2') format('woff2'), url('../fonts/pfhandbookpro-thin.woff') format('woff'), url('../fonts/pfhandbookpro-thin.ttf') format('truetype'), url('../fonts/pfhandbookpro-thin.svg#pfhandbookprothin') format('svg');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'PFHandbookPro';
  src: url('../fonts/pfhandbookpro-bold.eot');
  src: url('../fonts/pfhandbookpro-bold.eot?#iefix') format('embedded-opentype'), url('../fonts/pfhandbookpro-bold.woff2') format('woff2'), url('../fonts/pfhandbookpro-bold.woff') format('woff'), url('../fonts/pfhandbookpro-bold.ttf') format('truetype'), url('../fonts/pfhandbookpro-bold.svg#pfhandbookprobold') format('svg');
  font-weight: 700;
  font-style: normal;
}

body {
  color: #000;
}

h2 {
  margin-bottom: 40px;
  font-size: 65px;
  text-align: center;

  @media all and (max-width: 767px) {
    font-size: 40px;
  }
}

p {
  margin-bottom: 20px;
}

a:hover,
a:focus {
  text-decoration: none;
  outline: none;
}

img {
  max-width: 100%;
  height: auto;
}

.ahe-button {
  display: block;
  width: 148px;
  height: 46px;
  padding: 5px 10px 10px;
  background: #ff6800;
  border: none;
  border-bottom: 2px solid #081902;
  border-radius: 0;
  color: #fff;
  font-size: 25px;
  font-weight: 300;
  text-shadow: 0 11px 18px rgba(0, 0, 0, 0.13);
  text-align: center;
  outline: none;

  &:hover,
  &:focus {
    color: #fff;
    outline: none;
  }
}

#messages {
  position: absolute;
  top: 20%;
  z-index: 1;
}

.tabs {
  margin: 0;
}


/*-----------------------
      Scrollbar
-----------------------*/

::-webkit-scrollbar-button {
  background-image: url("");
  background-repeat: no-repeat;
  width: 6px;
  height: 0px;
}

::-webkit-scrollbar-track {
  background-color: #0c356d;
  box-shadow: 0px 0px 3px #000 inset;
}

::-webkit-scrollbar-thumb {
  border-radius: 0;
  background-color: #ff6800;
  box-shadow: 0px 1px 1px #3995ff inset;
  //background-image: url(../images/scroll-img.png);
  background-position: center;
  background-repeat: no-repeat;
}

::-webkit-resizer {
  background-image: url("");
  background-repeat: no-repeat;
  width: 9px;
  height: 0px;
}

::-webkit-scrollbar {
  width: 9px;
}