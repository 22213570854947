.page-contact {
  .top {
    background: rgba(4, 30, 61, 0.7) url("/sites/all/themes/wx_theme/assets/images/bg-contact.jpg") 50% 50% no-repeat;
  }
}

.page-sto {
  .top {
    background: rgba(4, 30, 61, 0.7) url("/sites/all/themes/wx_theme/assets/images/bg-sto.jpg") 50% 50% no-repeat;
  }
}

.page-contact,
.page-sto {
  .top {
    height: 450px;
    background-size: cover;
    user-select: none;
    cursor: default;

    @media all and (max-width: 767px) {
      height: 300px;
    }

    .inner {
      display: flex;
      align-items: center;
      height: 100%;
      padding-top: 100px;
      background: rgba(4, 30, 61, 0.7) url("/sites/all/themes/wx_theme/assets/images/shadow.png") 50% 50% no-repeat;
    }

    h1 {
      text-align: center;
      color: #fff;
      font-size: 65px;
      font-weight: 700;
      text-shadow: 0 11px 18px rgba(0, 0, 0, 0.13);
      text-transform: uppercase;
    }
  }
}

.page-sto {
  .body {
    padding: 50px 50px 60px;
    font-size: 28px;
    font-weight: 300;

    @media all and (max-width: 991px) {
      padding: 30px 15px 60px;
    }
  }

  @media all and (max-width: 767px) {
    .top h1 {
      font-size: 47px;
    }
  }
}

.page-contact {
  h2 {
    font-size: 35px;
    font-weight: 700;
    line-height: 39px;
    text-align: left;
  }

  .center {
    padding: 30px 0 110px;

    [class^="contact-"] {
      position: relative;
      padding-left: 40px;

      a {
        color: #000;
        font-size: 25px;
        font-weight: 300;
        line-height: 35px;
      }

      &:before {
        content: '';
        position: absolute;
        left: 0;
      }
    }

    .contact-phone {
      &:before {
        width: 21px;
        height: 24px;
        top: 3px;
        background: url("/sites/all/themes/wx_theme/assets/images/sprite.png") -52px -42px no-repeat;
      }
    }

    .contact-email {
      &:before {
        width: 19px;
        height: 14px;
        top: 10px;
        background: url("/sites/all/themes/wx_theme/assets/images/sprite.png") -78px -42px no-repeat;
      }
    }

    .contact-skype {
      &:before {
        width: 19px;
        height: 19px;
        top: 8px;
        background: url("/sites/all/themes/wx_theme/assets/images/sprite.png") -102px -42px no-repeat;
      }
    }
  }
}

.yandex-map {
  height: 470px;
  filter: grayscale(50%);

  @media all and (max-width: 767px) {
    height: 330px;
  }
}

.ymaps-2-1-50-inner-panes {
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.webform-client-form-2 {
  max-width: 450px;

  .form-item input,
  textarea {
    padding: 6px 7px;
    background: transparent;
    color: #000;
    font-size: 25px;
    border: 0;
    border-bottom: 1px solid #d7d7d7;
    border-radius: 0;
    box-shadow: none;

    &::-webkit-input-placeholder {
      color: #828282;
      font-size: 23px;
      font-weight: 300;
      line-height: 25px;
    }

    &::-moz-placeholder {
      color: #828282;
      font-size: 23px;
      font-weight: 300;
      line-height: 25px;
    }

    &:-moz-placeholder {
      color: #828282;
      font-size: 23px;
      font-weight: 300;
      line-height: 25px;
    }

    &:-ms-input-placeholder {
      color: #828282;
      font-size: 23px;
      font-weight: 300;
      line-height: 25px;
    }
  }

  textarea {
    &::-webkit-input-placeholder {
      padding-top: 40px;
    }

    &::-moz-placeholder {
      padding-top: 40px;
    }

    &:-moz-placeholder {
      padding-top: 40px;
    }

    &:-ms-input-placeholder {
      padding-top: 40px;
    }
  }

  .form-item input:focus {
    border-bottom: 1px solid #000;
  }

  .grippie {
    display: none;
  }

  textarea {
    resize: none;
    max-height: 80px;

    &:focus {
      border: none;
      border-bottom: 1px solid #1e3557;
      box-shadow: none;
    }
  }

  .form-actions {
    margin-top: 25px;
  }

  input[type="submit"] {
    @extend .ahe-button;

    &:focus {
      background: #ff6800;
      outline: none;
    }
  }
}


/*------USER-----*/
.page-user {
  #main-wrapper {
    padding-top: 140px;
  }

  input {
    border-radius: 0;
  }

  input[type="submit"] {
    @extend .ahe-button;
  }
}
