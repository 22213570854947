header {
  position: fixed;
  width: 100%;
  background-color: rgba(0,0,0,0.6);
  z-index: 100;

  .header-iw {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100px;
  }

  .header-rs {
    display: flex;
    align-items: center;
  }

  .site-name a {
    color: #fff;
    font-size: 44px;
    font-weight: 400;
  }

  .phone {
    position: relative;
    margin-left: 45px;

    &:before {
      content: '';
      position: absolute;
      width: 25px;
      height: 29px;
      top: 3px;
      left: -45px;
      background: url("/sites/all/themes/wx_theme/assets/images/sprite.png") 0 0 no-repeat;
    }

    a {
      color: #fff;
      font-size: 30px;
    }

    span {
      font-weight: 300;
      font-family: 'PFHandbookPro', sans-serif;
    }
  }

  .main-menu {
    & > button {
      margin: 8px 0 8px 95px;

      .icon-bar {
        width: 36px;
        background: #fff;
        border-radius: 0;

        & + .icon-bar {
          margin-top: 7px;
        }
      }
    }
  }
}


/*-----------------------
          Footer
------------------------*/

footer {
  background: url("/sites/all/themes/wx_theme/assets/images/bg-footer.jpg") 0 0 no-repeat;
  background-size: cover;
  color: #fff;

  .footer-iw {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 150px;
  }

  .site-name {
    margin-bottom: 12px;
    font-size: 30px;
    line-height: 32px;
  }

  .email a,
  .address {
    color: #fff;
    font-size: 18px;
    font-weight: 300;
  }

  .phone {
    position: relative;
    margin-left: 32px;

    &:before {
      content: '';
      position: absolute;
      width: 17px;
      height: 19px;
      top: 2px;
      left: -32px;
      background: url("/sites/all/themes/wx_theme/assets/images/sprite.png") -30px 0 no-repeat;
    }

    a {
      color: #fff;
      font-size: 20px;
    }

    span {
      font-weight: 300;
      font-family: 'PFHandbookPro', sans-serif;
    }
  }

  .b-developer {
    display: flex;
    align-items: center;
    margin: 20px 0 0 -5px;

    .dev-icon {
      float: left;
      margin-right: 5px;
    }

    .dev-text {
      font-size: 11px;
      font-weight: 300;
      line-height: 1.3;

      a {
        display: block;
        color: #fff;
        font-size: 13px;
        font-weight: 300;
        line-height: 13px;
      }
    }
  }
}


@media all and (max-width: 767px) {
  header {

    .header-iw {
      position: relative;
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
      padding: 0 15px;
      height: 80px;
    }

    .site-name a {
      font-size: 28px;
    }

    .phone {
      margin-left: 32px;

      &:before {
        width: 17px;
        height: 19px;
        top: 2px;
        left: -32px;
        background: url("/sites/all/themes/wx_theme/assets/images/sprite.png") -30px 0 no-repeat;
      }

      a {
        font-size: 23px;
      }
    }

    .main-menu {
      position: absolute;
      height: 100vh;
      right: 15px;
      //top: 50%;
      top: 10px;
      z-index: 1000;
      //transform: translateY(-50%);

      & > button {
        margin: 8px 0 8px 15px;
      }
    }
  }

  footer {
    .footer-iw {
      padding: 0 15px;
    }

    .site-name {
      font-size: 24px;
    }

    .email a,
    .address {
      font-size: 16px;
    }

    .phone a {
      font-size: 17px;
    }

    .b-developer {
      justify-content: flex-end;
    }
  }
}