#navbar-collapse {
  position: fixed;
  top: 0;
  right: 0;
  padding: 0;
  border-left: 1px solid #eee;
  transition: none;
  z-index: 100;

  button {
    position: absolute;
    top: 0;
    left: 0;
    width: 25px;
    height: 25px;
    border: none;
    border-radius: 0;
    background: url("/sites/all/themes/wx_theme/assets/images/modal-close.png") 50% 50% no-repeat;
    outline: none;
    z-index: 120;
  }
}

.navbar-collapse.collapsing {
  transition: none;
}

.navbar-collapse.in {
  overflow: visible;
}

.menu.nav {
  margin: 0;
  min-width: 300px;
  height: 100vh;
  background: #fff;
  z-index: 110;

  li {
    a {
      color: #000;
      font-size: 30px;
      font-weight: 300;
      line-height: 34px;
    }
  }

  & > li {
    & > a {
      padding: 22px 30px 10px 50px;

      &.active,
      &:focus,
      &:hover {
        background: #f5f5f5;
      }
    }

    &.active {
      & > a {
        background: #f5f5f5;
      }
    }

    &.open {
      position: initial;

      & > a {
        background: #f5f5f5;
      }
    }
  }

  li.expanded {
    cursor: pointer;

    & > a {
      position: relative;

      &:before {
        content: '';
        position: absolute;
        width: 8px;
        height: 18px;
        top: 30px;
        left: 30px;
        background: url("/sites/all/themes/wx_theme/assets/images/sprite.png") -214px 0 no-repeat;
      }

      span {
        display: none;
      }
    }
  }

  li:not(:first-child) > a {
    position: relative;

    &:after {
      content: '';
      position: absolute;
      width: calc(100% - 50px);
      height: 1px;
      top: 0;
      left: 50%;
      background: #eee;
      transform: translateX(-50%);
    }
  }

  li.active > a,
  li.active + li > a,
  li.open > a,
  li.open + li > a,
  li:hover > a,
  li:hover + li > a {
    &:after {
      content: none;
    }
  }

  .dropdown-menu {
    display: none;
    position: absolute;
    max-height: 100vh;
    left: -400px;
    top: 0;
    width: 400px;
    height: 100vh;
    padding: 0;
    border-left: 1px solid #eee;
    border-radius: 0;
    background: rgba(255,255,255,0.95);
    overflow-y: auto;

    & > li {
      & > a {
        padding: 22px 30px 10px;
        line-height: 34px;
        white-space: normal;
      }

      & > a:hover,
      & > a:focus {
        background: #e5e6e7;
      }

      &.active {
        & > a,
        & > a:hover,
        & > a:focus {
          background: #e5e6e7;
          color: #000;
        }
      }
    }
  }

  li.open {
    .dropdown-menu {
      display: block;
    }
  }
}


@media all and (max-width: 767px) {
  .main-menu {
    #navbar-collapse {
      button {
        top: -12px;
        right: -15px;
      }
    }
  }

  .menu.nav {
    top: -12px;
    right: -15px;
    width: 160px;
    min-width: auto;

    li a {
      font-size: 21px;
      line-height: 26px;
    }

    & > li > a {
      padding: 22px 15px 10px 30px;

    }

    li.expanded > a:before {
      top: 25px;
      left: 12px;
    }

    .dropdown-menu {
      left: -160px;
      width: 150px;

      & > li {
        & > a {
          padding: 12px 15px 8px;
          line-height: 26px;
        }
      }
    }
  }
}
